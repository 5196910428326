import React, { useState, useEffect } from "react";
import { req } from "../../utils";

import { Grid } from "@mui/material/";

import machineIcon from "../../assets/images/dryer-alt-regular.svg";

export const PendingInstallsRemovals = ({ filterProps }) => {
  const { dateRanges, pageFilterByType, pageFilterByState, selectedDateRange } =
    filterProps;
  const [stats, setStats] = useState([]);
  const [historicalStats, setHistoricalStats] = useState([]);
  const [updateDate, setUpdateDate] = useState(new Date());
  const [forRegion, setForRegion] = useState("");
  const [dateSpan, setDateSpan] = useState("Last 30 Days");
  const getData = () => {
    req("GET", "pending_installs_removals").then((data) => {
      setUpdateDate(new Date());
      setStats([
        { label: "Installs", value: data.pending_installs?.toLocaleString() },
        { label: "Removals", value: data.pending_removals?.toLocaleString() },
      ]);
    });
    //historical data

    let url = `historical_installs_removals`;
    //start and end dates are last 30 days by default as unix timestamps
    let start_date = new Date();
    start_date.setDate(start_date.getDate() - 30);
    start_date = start_date.getTime() / 1000;
    let end_date = new Date();
    end_date = end_date.getTime() / 1000;
    if (selectedDateRange && selectedDateRange.length === 2) {
      setDateSpan(
        `${selectedDateRange[0].toLocaleDateString()} - ${selectedDateRange[1].toLocaleDateString()}`
      );
      start_date = selectedDateRange[0].getTime() / 1000;
      end_date = selectedDateRange[1].getTime() / 1000;
    }
    //round start and and dates
    start_date = Math.floor(start_date);
    end_date = Math.floor(end_date);
    url += `?s=${start_date}&e=${end_date}`;
    //if region in filter add that
    if (pageFilterByState) {
      setForRegion(pageFilterByState);
      url += `&region=${pageFilterByState}`;
    }
    req("GET", url).then((data) => {
      let Installs = 0;
      let Removals = 0;
      if (data && data.length) {
        data.forEach((item) => {
          //if item.service_call_type =='Machine Installation'
          if (item.service_call_type === "Machine Installation") {
            Installs = item.nbr;
          } else if (item.service_call_type === "Machine Removal") {
            Removals = item.nbr;
          }
        });
      }
      setUpdateDate(new Date());
      setHistoricalStats([
        { label: "Installs", value: Installs.toLocaleString() },
        { label: "Removals", value: Removals.toLocaleString() },
      ]);
    });
  };

  useEffect(() => {
    getData();
  }, [filterProps]);

  const formatNumber = (number) => {
    try {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (e) {
      return number;
    }
  };

  // Update the footer text based on the last refresh time
  const footerText = `Updated: ${updateDate.toLocaleTimeString()}`;

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}

        className={`${
          pageFilterByType !== "unfiltered" && pageFilterByType !== "machine"
            ? "d-none"
            : ""
        }`}
      >
        <div className="card card-stat-summary animate-bg-primary">
          <div className="card-body">
            <div className="card-stat-summary--title">
              Pending Machine Installs &amp; Removals
            </div>
            <div className="card-stat-summary--total-wrapper">
              {stats.map(({ label, value }, index) => (
                <div key={index} style={{ marginRight: "3rem" }}>
                  <div className="card-stat-summary--total">
                    {formatNumber(value)}
                    <div className="card-stat-summary--total-subtitle">
                      {label}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="card-stat-summary--title">
              Historical {forRegion} {dateSpan}
            </div>
            <div className="card-stat-summary--total-wrapper">
              {historicalStats.map(({ label, value }, index) => (
                <div key={index} style={{ marginRight: "3rem" }}>
                  <div className="card-stat-summary--total">
                    {formatNumber(value)}
                    <div className="card-stat-summary--total-subtitle">
                      {label}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="card-stat-summary--meta">
              <div className="card-stat-summary--meta-timeline">{`Updated: ${updateDate.toLocaleTimeString()}`}</div>
            </div>
          </div>
          <div className="card-bg">
            <img src={machineIcon} alt="Machine Icon" className="bg-icon" />
          </div>
        </div>
      </Grid>
      
    </>
  );
};
