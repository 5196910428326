import { useState } from 'react';

import { Button, FormControl, IconButton, MenuItem, Popover, Select } from '@mui/material';

import { useDataContext } from "../DataContext";

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

const filterTypes = [
    {
      value: 'environmental',
      label: 'Environmental Impact',
    },
    {
      value: 'contract',
      label: 'Contracts',
    },
    {
      value: 'machine',
      label: 'Machines',
    },
    {
      value: 'call',
      label: 'Service Call Insights',
    },
];

export default function Filters({ onApplyFilters }) {

	const { statesAndTerritories } = useDataContext();

    const [localPageFilterByType, setLocalPageFilterByType] = useState('unfiltered');
    const [localPageFilterByState, setLocalPageFilterByState] = useState('unfiltered');

    const handleTypeChange = (event) => {
        setLocalPageFilterByType(event.target.value);
    };

    const handleStateChange = (event) => {
        setLocalPageFilterByState(event.target.value);
    };

    const handleApplyClick = () => {
        onApplyFilters(localPageFilterByType, localPageFilterByState);
        handleClose();
    };

    const handleReset = () => {
        setLocalPageFilterByType('unfiltered');
        setLocalPageFilterByState('unfiltered');
		onApplyFilters('unfiltered', 'unfiltered');
		handleClose();
    }
    
    /** Menu */
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    
	const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<>
        <Button variant='outlined' onClick={handleClick} color='persiangreen' sx={{ ml: 1, px: 1 }} style={{ minWidth: "0" }}>
            <FilterAltOutlinedIcon />
        </Button>
        <Popover 
            anchorEl={anchorEl}
            id="filtersPopover"
            open={open}
            onClose={handleClose}
            slotProps={{
                paper:{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 16,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <div className="popover-inner">
                <div className="popover-header">
                    <span>Filters</span>
                    <IconButton onClick={handleClose} size='small'>
                        <CloseRoundedIcon fontSize='small' />
                    </IconButton>
                </div>
                <div className="popover-content">
                    <div className="popover--filter-item">
                        <FormControl fullWidth color='grey' size='small'>
                            <label id="labelFilterType">
                                <span>Filter by Type</span>
                                <a onClick={() => setLocalPageFilterByType('unfiltered')}>Reset</a>
                            </label>
                            <Select
                                labelId="labelFilterType"
                                id="selectFilterType"
                                onChange={handleTypeChange}
                                value={localPageFilterByType}
                            >
                                {filterTypes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="popover--filter-item">
                        <FormControl fullWidth color='persiangreen' size='small' sx={{ minWidth: "300px"}}>
                            <label id="labelFilterState">
                                <span>Filter by State/Territory</span>
                                <a onClick={() => setLocalPageFilterByState('unfiltered')}>Reset</a>
                            </label>
                            <Select
                                labelId="labelFilterState"
                                id="selectFilterState"
                                onChange={handleStateChange}
                                value={localPageFilterByState}
                            >
                                {statesAndTerritories.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="popover-actions">
                    <Button
                        variant='contained'
                        color='lightergrey'
                        size='small'
                        sx={{ boxShadow: 'none', mr: 1, px: 3, '&:hover': { boxShadow: 'none' } }}
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                    <Button
                        variant='contained'
                        color='persiangreen'
                        size='small'
                        sx={{ boxShadow: 'none', px: 3, '&:hover': { boxShadow: 'none' } }}
                        onClick={handleApplyClick}
                    >
                        Apply
                    </Button>
                </div>
            </div>
        </Popover>
    </>);
}