export const getDateRange = (dateRanges) => {
    if (dateRanges[0] && dateRanges[1]) {
      return {
        start: Math.floor(new Date(dateRanges[0]).getTime() / 1000),
        end: Math.floor(new Date(dateRanges[1]).getTime() / 1000)
      };
    } else {
      const end = Math.floor(Date.now() / 1000);
      const start = end - 30 * 24 * 60 * 60; // 30 days ago
      return { start, end };
    }
  };