// ... existing code ...

export const generateColorPalette = (count) => {
    const baseColors = [
        '#00A99D', // Teal (complementary to the header color)
        '#FF6B6B', // Coral
        '#4ECDC4', // Turquoise
        '#45B7D1', // Sky Blue
        '#FFA07A', // Light Salmon
        '#98D8C8', // Seafoam
        '#F7B267', // Mellow Orange
        '#6A0572', // Deep Purple
        '#7FDBDA', // Light Teal
        '#FB3640', // Bright Red
        '#AED9E0', // Powder Blue
        '#FFA600', // Amber
        '#B3E5FC', // Light Blue
        '#C7EFCF', // Mint
        '#FFC300', // Yellow
    ];

    // If we need more colors, we'll generate variations
    if (count <= baseColors.length) {
        return baseColors.slice(0, count);
    }

    const extendedPalette = [...baseColors];
    while (extendedPalette.length < count) {
        const index = extendedPalette.length % baseColors.length;
        const baseColor = baseColors[index];
        const newColor = adjustBrightness(baseColor, (extendedPalette.length - baseColors.length) * 10);
        extendedPalette.push(newColor);
    }

    return extendedPalette;
};

const adjustBrightness = (hex, percent) => {
    const num = parseInt(hex.replace('#', ''), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const G = (num >> 8 & 0x00FF) + amt;
    const B = (num & 0x0000FF) + amt;
    return `#${(1 << 24 | (R < 255 ? R < 1 ? 0 : R : 255) << 16 | (G < 255 ? G < 1 ? 0 : G : 255) << 8 | (B < 255 ? B < 1 ? 0 : B : 255)).toString(16).slice(1)}`;
};