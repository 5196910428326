import React, { useState, useEffect } from "react";
import { req } from "../../utils";

import { Grid } from "@mui/material/";

import machineIcon from '../../assets/images/dryer-alt-regular.svg';

export const Machines = ({ filterProps }) => {
  const {
    dateRanges,
    pageFilterByType,
    pageFilterByState,
    selectedDateRange,
  } = filterProps;

  const [machineStats, setMachineStats] = useState([]);
  const [updateDate, setUpdateDate] = useState(new Date());

  useEffect(() => {
    const fetchMachines = async () => {
      try {
        const data = await req("GET", "machines");
        const nbrDeployed = data.total_machines - data.nbr_machines_in_warehouse;
        const deployedRatio = (nbrDeployed / data.total_machines) * 100;
        setUpdateDate(new Date());
        setMachineStats([
          { label: 'Deployed', value: nbrDeployed },
          { label: 'In Warehouse', value: data.nbr_machines_in_warehouse },
          { label: 'Total', value: data.total_machines },
          { label: 'Deployment %', value: deployedRatio.toFixed(2) }
        ]);
      } catch (error) {
        console.error('Error fetching machine data:', error);
      }
    };

    fetchMachines();
    // Set up an interval to fetch machine data every 5 minutes
    const intervalId = setInterval(fetchMachines, 300000);
    return () => clearInterval(intervalId);
  }, []);

  const formatNumber = (number) => {
    try {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (e) {
      return number;
    }
  };

  return (<>
    <Grid item xs={12} sm={12} className={`${pageFilterByType !== 'unfiltered' && pageFilterByType !== 'machine' ? 'd-none' : ''}`}>
      <div className="card card-stat-summary animate-bg-primary">
        <div className="card-body">
          <div className="card-stat-summary--title">Machine Deployment</div>
          <div className="card-stat-summary--total-wrapper ">
            {machineStats.map(({ label, value }, index) => (
              <div key={index} style={{ marginRight: "3rem" }}>
                <div className="card-stat-summary--total">
                  {formatNumber(value)}
                  <div className="card-stat-summary--total-subtitle">{label}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="card-stat-summary--meta">
            <div className="card-stat-summary--meta-timeline">{`Updated: ${updateDate.toLocaleTimeString()}`}</div>
          </div>
        </div>
        <div className="card-bg">
          <img src={machineIcon} alt="Machine Icon" className="bg-icon" />
        </div>
      </div>
    </Grid>
  </>);
};
