import React, { useState, useEffect } from "react";
import { req } from "../../utils";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { DashboardStat } from "./DashboardStat";
import moment from "moment";

import icon from '../../assets/images/phone-rotary-regular.svg';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const PmQueue = ({ filterProps }) => {
	const {
        dateRanges,
        pageFilterByType,
        pageFilterByState,
        selectedDateRange,
      } = filterProps;


	const [loading, setLoading] = useState(true);
  const [jobStats, setJobStats] = useState([]);
  const [updateDate, setUpdateDate] = useState(new Date());
  const [data, setData] = useState([]);

  
  
  const getaverageDaysOverdue = (jobs) => {
    let sum = 0;
    jobs.forEach((job) => {
      let details = JSON.parse(job.reason_for_urgency_score);
      sum += parseInt(details?.daysOverdue);
    });
    let avg = sum / jobs.length;
    
     return avg.toFixed(2);
  };

  const getData = async () => {
	if (!dateRanges) return;

	try {
        let url = "get_pm_queue_stats";
        let startDate = Math.floor(new Date().setDate(new Date().getDate() - 30) / 1000);
        let endDate = Math.floor(new Date().getTime() / 1000);

        if(dateRanges) {
          startDate = Math.floor(new Date(dateRanges[0]).getTime() / 1000);
          endDate = Math.floor(new Date(dateRanges[1]).getTime() / 1000);
        }
        if(isNaN(startDate))
          //start date is 30 days ago
          startDate = Math.floor(new Date().setDate(new Date().getDate() - 30) / 1000);
        if(isNaN(endDate))
          //end date is now
          endDate = Math.floor(new Date().getTime() / 1000);
        url += `?s=${startDate}&e=${endDate}`;
      if(!url.includes("?"))
        return;
    	let rawData = await req("GET", url);
      setData(rawData);
      //console.log("Raw PQ queue data", rawData);
      //let moreStats = calculateStats(rawData);
      //console.log("More stats", moreStats);

		setUpdateDate(new Date());

	} catch (e) {
		console.error("PM queue error",e);
	} finally {
		setLoading(false);
	}
  };
  const calculateStats = (rawData) => {
    const { completed_jobs, current_queue } = rawData;
    const now = moment();

    const totalInQueue = current_queue.length;
    const completedJobsCount = completed_jobs.length;

    const mostRecentJob = completed_jobs.reduce((mostRecent, job) => {
      // Convert string timestamps to numbers for comparison
      return parseInt(mostRecent.time_completed) > parseInt(job.time_completed) ? mostRecent : job;
    }, completed_jobs[0]);
    const numberOfJobsCompleted = completed_jobs.length;
    const oldestCompletedJobTime = Math.min(...completed_jobs.map(job => parseInt(job.time_completed)));
    const numberOfJobsCompletedPerDay = (numberOfJobsCompleted / (now.diff(moment.unix(oldestCompletedJobTime), 'days') || 1)).toFixed(2);
    const overdueTimes = current_queue.map(job => {
      const dueDate = moment(job.pm_due_date);
      return now.diff(dueDate, 'minutes');
    });
    const averageOverdueMinutes = overdueTimes.reduce((sum, time) => sum + time, 0) / overdueTimes.length;
    const numberOfJobsNeedingAdvanceBooking = current_queue.filter(job => job.access_right_now === "Must be booked").length;
    const formatOverdueTime = (minutes) => {
      const days = Math.floor(minutes / (24 * 60));
      const hours = Math.floor((minutes % (24 * 60)) / 60);
      const remainingMinutes = Math.floor(minutes % 60);
  
      const parts = [];
      if (days > 0) parts.push(`${days} day${days !== 1 ? 's' : ''}`);
      if (hours > 0) parts.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
      if (remainingMinutes > 0) parts.push(`${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`);
  
      return parts.join(', ');
    };
    const averageOverdueFormatted = formatOverdueTime(averageOverdueMinutes);
    //converty the most recent time to dd/mm/yyyy hh:mm:ss
    const mostRecentJobTime = moment.unix(mostRecentJob?.time_completed).format("DD/MM/YYYY HH:mm:ss");
    return ([
      
      { label: "Completed", value: completedJobsCount },
      { label: "Average Overdue", value: averageOverdueFormatted },
      { label: "Advance booking", value: numberOfJobsNeedingAdvanceBooking },
      { label: "Avg PMs / day", value: numberOfJobsCompletedPerDay },
      { label: "Jobs in queue", value: totalInQueue },
      { label: "Most recently completed", value: mostRecentJobTime },
    ]);
  };

  useEffect(() => {
    getData();
  }, [dateRanges]);

  const footerText = dateRanges
  ? `${new Date(dateRanges[0]).toLocaleString()} - ${new Date(dateRanges[1]).toLocaleString()}`
  : "";

  const formatNumber = (number) => {
    try {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (e) {
      return number;
    }
  };


	if (loading) {
		return <div>Loading...</div>;
	}

	if (!data?.completed_jobs?.length || !data?.current_queue?.length) {
		return null;
	}

  return (<>
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} lg={6}>
        <div className="card card-stat-summary animate-bg-primary">
          <div className="card-body">
            <div className="card-stat-summary--title">Preventative Maintenance Queue</div>
            <div className="card-stat-summary--total-wrapper">
              {calculateStats(data).map(({ label, value }, index) => (
                <React.Fragment key={index}>
                  {index % 3 === 0 && index !== 0 && <div key={`divider-${index}`} style={{ width: "100%" }}></div>}
                  <div className="card-stat-summary--total" style={{ marginRight: "2rem" }}>
          
                    <div class="card-stat-summary--total">
                      {value}
                      <div class="card-stat-summary--total-subtitle">{label}</div></div>

                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className="card-stat-summary--footer">{footerText}</div>
          </div>
          <div className="card-bg">
            <img src={icon} alt="Machine Icon" className="bg-icon" />
          </div>
        </div>
      </Grid>

    </Grid>
  </>);
};
