import React, { useState, useEffect } from 'react';
import { 
    Chart as ChartJS, 
    CategoryScale, 
    LinearScale, 
    BarElement,
    Title, 
    Tooltip, 
    Legend,
    TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Bar } from 'react-chartjs-2';
import {req} from '../../utils.js';
import { generateColorPalette } from "../../utils/generateColorPalette.js";
import { Card, CardContent, CardHeader } from '@mui/material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
);

export const ServiceTechJobsCompleted = ({ dateRanges }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });

    useEffect(() => {
        if(!dateRanges.length) return;
        const fetchData = async () => {
            const start = new Date(dateRanges[0]).getTime() / 1000;
            const end = new Date(dateRanges[1]).getTime() / 1000;

            const result = await req("GET", `get_st_completed_jobs?s=${start}&e=${end}`);
            const jobsPerDay = {};

            // Group data by day and technician
            result.forEach(job => {
                const day = new Date(job.time_completed * 1000).toISOString().split('T')[0]; // Converts timestamp to YYYY-MM-DD
                const techName = job.service_tech;
                if (!jobsPerDay[day]) {
                    jobsPerDay[day] = {};
                }
                if (!jobsPerDay[day][techName]) {
                    jobsPerDay[day][techName] = 0;
                }
                jobsPerDay[day][techName]++;
            });

            // Prepare datasets for the chart
            const datasets = [];
            const allTechs = new Set();
            Object.keys(jobsPerDay).forEach(day => {
                Object.keys(jobsPerDay[day]).forEach(tech => {
                    allTechs.add(tech);
                });
            });

            const colorPalette = generateColorPalette(15);

            Array.from(allTechs).forEach((tech, index) => {
                const data = Object.keys(jobsPerDay).map(day => ({
                    x: day,
                    y: jobsPerDay[day][tech] || 0
                }));
                datasets.push({
                    label: tech,
                    data,
                    backgroundColor: colorPalette[index % colorPalette.length],
                    borderColor: colorPalette[index % colorPalette.length],
                    tension: 0.1
                });
            });

            setChartData({
                labels: Object.keys(jobsPerDay).sort(),
                datasets
            });
        };
        fetchData();
    }, [dateRanges]);

    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day'
                },
                title: {
                    display: true,
                    text: 'Date'
                },
                stacked: true
            },
            y: {
                stacked: true,
                title: {
                    display: true,
                    text: 'Number of Jobs'
                }
            }
        },
        plugins: {
            tooltip: {
                mode: 'index'
            }
        }
    };

    return chartData.labels.length ? (
        <Card>
            <CardHeader title="Jobs Completed by Staff" />
            <CardContent
                sx={{
                maxHeight: '600px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                }}
            >
                <Bar data={chartData} options={options} />
            </CardContent>
        </Card>
    ) : null;
};