import React, {useEffect, useState, createContext, useContext, useCallback} from "react";
import { req, log } from "./utils"

const DataContext = createContext(null);

function DataProvider({ children }) {
  const adminIds = [1, 999, 910, 836, 702];
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [dateRanges, setDateRanges] = useState([]);

  const statesAndTerritories = [
    { value: 'NSW', label: 'New South Wales' },
    { value: 'VIC', label: 'Victoria' },
    { value: 'QLD', label: 'Queensland' },
    { value: 'WA', label: 'Western Australia' },
    { value: 'SA', label: 'South Australia' },
    { value: 'TAS', label: 'Tasmania' },
    { value: 'ACT', label: 'Australian Capital Territory' },
    { value: 'NT', label: 'Northern Territory' }
  ];

  useEffect(() => {

    const handleOnlineStatusChange = () => setIsOnline(navigator.onLine);
    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  const contextValue = {
    isOnline,
    isAdmin,
    setDateRanges,
    dateRanges,
	statesAndTerritories
  };

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
}

function useDataContext() {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
}

export { DataProvider, useDataContext };
