import React, { useEffect, useState } from "react";

import YearRange from "../../components/YearRange";
import { req } from "../../utils";

import {
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@mui/material/";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const MachineInstallsRemovals = ({ filterProps }) => {
  const { selectedYearRange, pageFilterByState } = filterProps;

  //console.log(selectedYearRange, pageFilterByState);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rawData, setRawData] = useState([]);
  const [chartData, setChartData] = useState({});
  const [totals, setTotals] = useState({ installs: 0, removals: 0 });

  const [componentYearRange, setComponentYearRange] = useState(
    new Date().getFullYear()
  );

  const handleYearRangeChange = (yearRange) => {
    setComponentYearRange(yearRange);
  };

  const getYearTimestamps = () => {
    const startOfYear = new Date(componentYearRange, 0, 1, 0, 0, 0, 0);
    const yearStart = Math.floor(startOfYear.getTime() / 1000);

    const endOfYear = new Date(componentYearRange, 11, 31, 23, 59, 59, 999);
    const yearEnd = Math.floor(endOfYear.getTime() / 1000);

    return { yearStart, yearEnd };
  };

  const transformData = (filteredData) => {
    return filteredData.reduce((acc, curr) => {
      const date = new Date(
        parseInt(curr.time_completed) * 1000
      ).toLocaleDateString("en-AU");
      if (!acc[date]) {
        acc[date] = { installs: 0, removals: 0 };
      }
      if (curr.service_call_type === "Machine Installation") {
        acc[date].installs += 1;
      } else if (curr.service_call_type === "Machine Removal") {
        acc[date].removals += 1;
      }
      return acc;
    }, {});
  };

  const applyFilters = (data) => {
    let filteredData = [...data]; // Create a copy of data array
    // Apply filters
    if (pageFilterByState !== "unfiltered" && pageFilterByState !== "") {
      filteredData = filteredData.filter(
        (item) => item.deposit_region === pageFilterByState
      );
    }
    // Add more filters as needed
    return filteredData;
  };

  const getData = async () => {
    const { yearStart, yearEnd } = getYearTimestamps();

    try {
      const response = await req(
        "GET",
        `get_machine_installs_removals?s=${yearStart}&e=${yearEnd}`
      );
      //console.log("installs and removels", response);
      //calculate totals for installs and removals
      const totals = response.reduce(
        (acc, curr) => {
          if (curr.service_call_type === "Machine Installation") {
            acc.installs += 1;
          } else if (curr.service_call_type === "Machine Removal") {
            acc.removals += 1;
          }
          return acc;
        },
        { installs: 0, removals: 0 }
      );
      setTotals(totals);
      setRawData(response); // Store raw data
      setLoading(false);
    } catch (error) {
      setError(`Error fetching data: ${error.message}`);
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [componentYearRange]);

  useEffect(() => {
    // Apply filters to raw data
    const filteredData = applyFilters(rawData);
    // Transform filtered data
    const transformedData = transformData(filteredData);
    setChartData(transformedData);
  }, [rawData, pageFilterByState]);

  const BarChart = ({ data }) => {
    const labels = Object.keys(data);
    const installsData = labels.map((label) => data[label].installs);
    const removalsData = labels.map((label) => data[label].removals);

    const chartData = {
      labels,
      datasets: [
        {
          label: "Installs",
          data: installsData,
          backgroundColor: "#00A896", // Darker teal
        },
        {
          label: "Removals",
          data: removalsData,
          backgroundColor: "#F9C80E", // Complementary yellow
        },
      ],
    };

    const options = {
      responsive: true,
      scales: {
        x: {
          stacked: true,
          title: {
            display: true,
            text: "Date",
          },
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: "Count",
          },
          ticks: {
            precision: 0,
            beginAtZero: true,
          },
        },
      },
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Machine Installs and Removals",
        },
      },
    };

    return <Bar data={chartData} options={options} />;
  };

  return (
    <Grid
      item
      xs={12}
    >
    <Card>
      <CardHeader
        action={<YearRange onChangeYear={handleYearRangeChange} />}
        title="Yearly Machine Installs and Removals"
      />
      <CardContent>
        {loading ? (
          <Grid container justifyContent="center">
            <Grid item style={{ padding: "5rem 0" }}>
              <CircularProgress color="persiangreen" />
            </Grid>
          </Grid>
        ) : error ? (
          <>
            <p>There was an error loading the data. Please refresh the page.</p>
            <p>{error}</p>
          </>
        ) : (
          <>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item columns={6}>
                Total installs: {totals.installs}
              </Grid>
              <Grid item columns={6}>
                Total removals: {totals.removals}
              </Grid>
            </Grid>
            <BarChart data={chartData} />
          </>
        )}
      </CardContent>
    </Card>
    </Grid>
  );
};

