/**
 * TODO:
 * 
 * 1. Check for local storage tokens and logout if expired or do not exist.
 */

import "../assets/css/auth_v1.css";
import logo from '../assets/images/BC_logo_small.png';

import { useState, useEffect } from 'react';
import { Alert, Button, CircularProgress, Container, Snackbar, TextField } from '@mui/material';
import { req, log } from '../utils';
import { Outlet } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {

    const [token, setToken] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const [loginFailed, setlLoginFailed] = useState(false);
    const [forgotPassMessage, setForgotPassMessage] = useState(false);
    const local_storage_key = '_bc_dash_token';
    const local_storage_user_id = '_bc_dash_user_id';
    const auth_user_storage_name = '_bc_dash_user';

    const handleLogin = async () => {
        //add loading screen
        setLoading(true);
        try {
            const response = await req('post', 'https://app.bottlecycler.com/api/technicians/sign_in/', { email, password });
            console.log("login response", response);
            if (response.r) {
                localStorage.setItem(auth_user_storage_name, response.n)
                localStorage.setItem(local_storage_user_id, response.r)
                localStorage.setItem(local_storage_key, response.s);
            } else {
                // handle error, show MUI snackbar
                setLoading(false);
                setlLoginFailed(true);
            }
        } catch (error) {
            log('Login Error', error);
            setLoading(false);
            setlLoginFailed(true);
        }
    }

    const handleForgotPass = async () => {
        setLoading(true)
        try {
            const response = await req('post', 'forgot_pass', { email });
            if (response) {
                setForgotPassMessage(true);
            }
        } catch (error) {
            log('Forgot Password Error', error);
        }
        setLoading(false);
    }

    useEffect(() => {
        const storedToken = localStorage.getItem(local_storage_key);
        setToken(storedToken);
        const checkToken = setInterval(() => {
            try {
                const newToken = localStorage.getItem(local_storage_key);
                setToken(newToken);
                setLoading(false)
            } catch (error) {
                log('Token Error', error);
            }
        }, 1000);

        return () => clearInterval(checkToken);
    }, []);

    useEffect(() => {
        // Add class to body element
        if (!token) {
            document.body.classList.add('auth');
        } else {
            document.body.classList.remove('auth');
        }
    }, [token]);


    if (!token) {

        return (
            <Container>
                {loading &&
                    <div style={{ display: "table-cell", height: "100vh", textAlign: "center", verticalAlign: "middle", width: "100vw" }}>
                        <CircularProgress color="persiangreen" />
                    </div>
                }
                {!loading &&
                    <>
                        <div className="content">
                            <div className="bc--page--card-wrapper">
                                <div className=""><img src={logo} alt="BottleCycler logo" /></div>
                                <div className="bc--card">
                                    <div className="bc--card-title">Welcome Back!</div>
                                    <div className="bc--card-form">
                                        <div className={"login-area"}>
                                            <div className="input-block">
                                                <TextField
                                                    label="Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    type="email"
                                                    required={true}
                                                    onPaste={(e) => setEmail(e.clipboardData.getData('Text'))}
                                                    color="persiangreen"
                                                    sx={{ width: "100%" }}
                                                />
                                            </div>
                                            <div className="input-block">
                                                <TextField
                                                    label="Password"
                                                    type="password"
                                                    value={password}
                                                    required={true}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    color="persiangreen"
                                                    sx={{ width: "100%" }}
                                                />
                                            </div>
                                            <div className="input-block">
                                                <Button variant="contained" size="large" color="persiangreen" disableElevation={true} onClick={handleLogin} sx={{ width: "100%", height: "56px" }}>
                                                    Login
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="subtext">
                                            <Button variant="text" color="persiangreen" onClick={handleForgotPass}>
                                                Forgot Password
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Snackbar open={loginFailed} autoHideDuration={6000} onClose={() => { setlLoginFailed(false) }}>
                            <Alert onClose={() => { setlLoginFailed(false) }} severity="error" sx={{ width: '100%' }}>
                                Sorry, login failed, please try again.
                            </Alert>
                        </Snackbar>
                        <Snackbar open={forgotPassMessage} autoHideDuration={6000} onClose={() => { setForgotPassMessage(false) }}>
                            <Alert onClose={() => { setForgotPassMessage(false) }} severity="success" sx={{ width: '100%' }}>
                                Please check your inbox for a password reset link.
                            </Alert>
                        </Snackbar>
                    </>
                }
            </Container>
        );
    }

    return (
        <Outlet />
    );
};
export default ProtectedRoute;
