import React, { useState, useEffect, useCallback } from "react";
import { req } from "../../utils";
import { Grid, Typography } from "@mui/material/";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import fileContractIcon from '../../assets/images/file-contract-regular.svg';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const formatNumber = (number) => {
  try {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (e) {
    return number;
  }
};

export const Contracts = ({ filterProps }) => {
  const { dateRanges, pageFilterByType } = filterProps;
  const [applicationData, setApplicationData] = useState(null);
  const [updateDate, setUpdateDate] = useState(new Date());
  // Default to last 30 days if no date range is provided
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  
  const startDate = dateRanges[0] ? Math.floor(new Date(dateRanges[0]).getTime() / 1000) : Math.floor(thirtyDaysAgo.getTime() / 1000);
  const endDate = dateRanges[1] ? Math.floor(new Date(dateRanges[1]).getTime() / 1000) : Math.floor(Date.now() / 1000);
  const fetchApplicationsData = useCallback(async () => {
    const url = `get_customer_application_insights?s=${startDate}&e=${endDate}`;
    try {
      const data = await req("GET", url);
      setApplicationData(data);
      setUpdateDate(new Date());
    } catch (error) {
      console.error("Error fetching application data:", error);
    }
  }, [dateRanges]);

  useEffect(() => {
    fetchApplicationsData();
  }, [fetchApplicationsData]);

  const isVisible = pageFilterByType === 'unfiltered' || pageFilterByType === 'contract';

  if (!applicationData) return null;

  const chartData = {
    labels: applicationData.approvals_by_date.map(item => item.approval_date),
    datasets: [
      {
        label: 'Approvals',
        data: applicationData.approvals_by_date.map(item => parseInt(item.approval_count)),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Approvals by Date',
      },
    },
  };

  return (
    <Grid item xs={12} className={isVisible ? '' : 'd-none'}>
      <div className="card card-stat-summary">
        <div className="card-body">
          <div className="card-stat-summary--title">Contract Insights</div>
          
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <div className="contract-stat">
                <div className="contract-stat-value">{formatNumber(applicationData.total_contracts)}</div>
                <div className="contract-stat-label">Total Contracts</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="contract-stat">
                <div className="contract-stat-value">{formatNumber(applicationData.renewals)}</div>
                <div className="contract-stat-label">Renewals</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="contract-stat">
                <div className="contract-stat-value">{formatNumber(applicationData.new_contracts)}</div>
                <div className="contract-stat-label">New Contracts</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="contract-stat">
                <div className="contract-stat-value">{formatNumber(Math.round(parseFloat(applicationData.overall_avg_contract_length_in_days)))}</div>
                <div className="contract-stat-label">Avg Contract Length (days)</div>
              </div>
            </Grid>
          </Grid>
          
          <div style={{ height: '300px', marginTop: '20px', width: '100%' }}>
            <Bar data={chartData} options={chartOptions} />
          </div>

          <Typography variant="body1" style={{ marginTop: '20px' }}>
            <strong>Most Recently Approved:</strong> {applicationData.most_recently_approved_application}
          </Typography>
          <Typography variant="body1">
            <strong>Top Representative:</strong> {applicationData.top_representative}
          </Typography>
          <Typography variant="body1">
            <strong>Top Application State:</strong> {applicationData.top_application_state}
          </Typography>
          <Typography variant="body1">
            <strong>Most Common Contract Type:</strong> {applicationData.most_common_contract_type}
          </Typography>

          <div className="card-stat-summary--meta">
            <div className="card-stat-summary--meta-timeline">{`Updated: ${updateDate.toLocaleTimeString()}`}</div>
          </div>
        </div>
        <div className="card-bg">
          <img src={fileContractIcon} alt="File Contract Icon" className="bg-icon" />
        </div>
      </div>
    </Grid>
  );
};
