import React, { useState, useEffect, useRef } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';
import { Card, CardContent, CardHeader } from '@mui/material';
import { req } from "../../utils"; // Added import statement for 'req' function

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const ScanRatioChart = ({ dateRanges }) => {
    const [chartData, setChartData] = useState(null);
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (dateRanges && !dataFetchedRef.current) {
            fetchData();
        }
    }, [dateRanges]);

    const fetchData = async () => {
        // to unix timestamp
        let start = Math.floor(new Date().setDate(new Date().getDate() - 30) / 1000);
        let end = Math.floor(new Date().getTime() / 1000);
        if(dateRanges) {
            start = Math.floor(new Date(dateRanges[0]).getTime() / 1000);
            end = Math.floor(new Date(dateRanges[1]).getTime() / 1000);
        }
        if(isNaN(start) || isNaN(end)) {
            console.error("Invalid date range");
            return;
        }
        let url = `get_scans_over_time?s=${start}&e=${end}`;
        try {
            const result = await req("GET", url);
            if (result && result.length > 0) {
                setChartData({
                    labels: result.map(item => item.scan_date),
                    datasets: [
                        {
                            label: 'Scan Ratio',
                            data: result.map(item => ({
                                x: item.scan_date,
                                y: item.scan_ratio,
                                totalScans: item.total_scans,
                                scansWithNull: item.scans_with_null_upc,
                                scansWithUpc: item.scans_with_non_null_upc
                            })),
                            borderColor: 'rgb(75, 192, 192)',
                            tension: 0.1
                        }
                    ]
                });
                dataFetchedRef.current = true;
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    if (!dateRanges || !chartData) {
        return null;
    }

    const options = {
        tooltips: {
            mode: 'index',
            callbacks: {
                afterLabel: function(tooltipItem, data) {
                    // Assuming your dataset is the first one in the array
                    let dataset = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return [
                        `Total Scans: ${dataset.totalScans}`,
                        `Scans With UPC: ${dataset.scansWithUpc}`,
                        `Scans With Null: ${dataset.scansWithNull}`
                    ];
                }
            }
        }
    };
    return (
        <Card>
            <CardHeader title="Barcode Scanned Ratio" />
            <CardContent>
                <Line data={chartData} options={options} />
            </CardContent>
        </Card>
    );
};
