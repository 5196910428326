import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { format, subDays } from 'date-fns';
import { Button, Popover } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { useDataContext } from '../DataContext';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

function DateRange() {

  /** Menu */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /** Date Ranges */
  const { setDateRanges } = useDataContext();
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: 'selection',
      color: '#00A49B'
    }
  ]);

  const handleSelect = (ranges) => {
    const newSelection = ranges.selection;
    setState([newSelection]);
    
    // Check if both start and end dates are selected
    const unix1 = newSelection.startDate.getTime();
    const unix2 = newSelection.endDate.getTime();
    setDateRanges([newSelection.startDate, newSelection.endDate]);
    if (unix1 !== unix2) {
      
      // setIsOpen(false); // Close picker only when both dates are selected
      handleClose();
    }
  };

  // Format the dates for display on the button
  const formattedStartDate = format(state[0].startDate, 'MMM dd, yyyy');
  const formattedEndDate = format(state[0].endDate, 'MMM dd, yyyy');
  const dateRangeText = `${formattedStartDate} - ${formattedEndDate}`;
  useEffect(() => {
    setDateRanges([state[0].startDate, state[0].endDate]);
  }, [state, setDateRanges]);
  return (
    <div>
      <Button variant='outlined' onClick={handleClick} color='persiangreen'  endIcon={<CalendarMonthIcon />}>
        <span>{dateRangeText}</span>
      </Button>
      <Popover 
            anchorEl={anchorEl}
            id="filtersPopover"
            open={open}
            onClose={handleClose}
            slotProps={{
                paper:{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 16,
                        width: 10,
                        height: 10,
                        bgcolor: 'rgb(239, 242, 247)',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
          <DateRangePicker
            onChange={handleSelect}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
            maxDate={new Date()}
          />
        </Popover>
    </div>
  );
}

export default DateRange;
