import React, { useState, useEffect } from "react";
import { req } from "../../utils";
import { Grid, Typography, Divider, Box, Paper } from "@mui/material/";
import icon from '../../assets/images/phone-rotary-regular.svg';

export const ServiceCallInsights = ({ filterProps }) => {
  const {
    dateRanges,
    pageFilterByType,
    pageFilterByState,
    selectedDateRange,
  } = filterProps;
  //default to last 30 days
 
  const [serviceData, setServiceData] = useState({
    totalCalls: 0,
    completedCalls: 0,
    pendingCalls: 0,
    followUpsDue: 0,
    lateServiceCalls: 0,
    lastUpdated: ''
  });
  const [updateDate, setUpdateDate] = useState(new Date());
  const [dateRangeString, setDateRangeString] = useState('');

  const fetchServiceCallInsights = async () => {
    let startDate = Math.floor(new Date().getTime() / 1000) - 30 * 24 * 60 * 60;
    let endDate = Math.floor(new Date().getTime() / 1000);
    if (dateRanges[0] && dateRanges[1]) {
      startDate = Math.floor(new Date(dateRanges[0]).getTime() / 1000);
      endDate = Math.floor(new Date(dateRanges[1]).getTime() / 1000);
      
    }

    // Format date range
    const formatDate = (date) => {
      return new Date(date * 1000).toLocaleString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
    };

    setDateRangeString(`${formatDate(startDate)} - ${formatDate(endDate)}`);

    const url = `get_service_call_insights?s=${startDate}&e=${endDate}`;
    try {
      const data = await req("GET", url);
      //console.log("Service call insights", data);
      setUpdateDate(new Date());

      // Format average time to complete
      const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${hours}h ${minutes}m ${remainingSeconds}s`;
      };
      /* added needs scheduled 
       "need_to_schedule_counts": {
        "pm_to_schedule": "37",
        "installs_to_schedule": "0",
        "rescheduled_to_handle": "10"
    }
      
      */
      setServiceData({
        totalCalls: data.total_calls.toLocaleString(),
        completedCalls: data.completed_calls.toLocaleString(),
        pendingCalls: data.pending_calls.toLocaleString(),
        followUpsDue: data.follow_ups_due.toLocaleString(),
        lateServiceCalls: data.late_service_calls.toLocaleString(),
        averageTimeToComplete: formatTime(data.average_completion_time),
        lastUpdated: new Date().toLocaleString(),
        PmToSchedule: data.need_to_schedule_counts.pm_to_schedule,
        SleepingCalls: data.sleeping_calls,
        InstallsToSchedule: data.need_to_schedule_counts.installs_to_schedule,
        RescheduledToHandle: data.need_to_schedule_counts.rescheduled_to_handle,
        needsScheduled: data.need_to_schedule_counts.pm_to_schedule + data.need_to_schedule_counts.installs_to_schedule + data.need_to_schedule_counts.rescheduled_to_handle
      });
    } catch (error) {
      //console.error('Failed to fetch service call insights:', error);
    }
  };
  useEffect(() => {
    fetchServiceCallInsights();
  }, []);

  const timeBasedStats = [
    { label: 'Total Calls', value: serviceData.totalCalls },
    { label: 'Completed Calls', value: serviceData.completedCalls },
  ];

  const nonTimeBasedStats = [
    { label: 'Pending Calls', value: serviceData.pendingCalls },
    { label: 'Follow-ups Due', value: serviceData.followUpsDue },
    { label: 'Late Service Calls', value: serviceData.lateServiceCalls },
    { label: 'PM to schedule', value: serviceData.PmToSchedule },
    { label: 'Installs to schedule', value: serviceData.InstallsToSchedule },
    { label: 'Rescheduled to handle', value: serviceData.RescheduledToHandle },
    { label: 'Sleeping calls', value: serviceData.SleepingCalls },
  ];

  const formatNumber = (number) => {
    try {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (e) {
      return number;
    }
  };

  return (
    <Grid container spacing={2} className={`${pageFilterByType !== 'unfiltered' && pageFilterByType !== 'call' ? 'd-none' : ''}`}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box className="card card-stat-summary animate-bg-primary">
          <Box className="card-body">
            <Typography variant="h6" className="card-stat-summary--title">Service Call Insights</Typography>
            <Grid container spacing={1}>
              {timeBasedStats.map(({ label, value }, index) => (
                <Grid item xs={6} key={index}>
                  <Box className="card-stat-summary--total">
                    <Typography variant="h6">{formatNumber(value)}</Typography>
                    <Typography variant="caption" className="card-stat-summary--total-subtitle">{label}</Typography>
                  </Box>
                </Grid>
              ))}
              <Grid item xs={12} style={{ display: 'flex', justifyContent: '' }}>
                <Box className="card-stat-summary--total" textAlign="center">
                  <Typography variant="h6">{serviceData.averageTimeToComplete}</Typography>
                  <Typography variant="caption" className="card-stat-summary--total-subtitle">Avg. Time to Complete</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="card-stat-summary--meta" mt={2}>
                  <Typography variant="body2">
                    <span className="text-persiangreen">Date Range:</span> {dateRangeString}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="card-bg">
            <img src={icon} alt="Service Calls Icon" className="bg-icon" />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box className="card card-stat-summary animate-bg-secondary">
          <Box className="card-body">
            <Typography variant="h6" className="card-stat-summary--title">Current Service Tasks</Typography>
            <Grid container spacing={1}>
              {nonTimeBasedStats.map(({ label, value }, index) => (
                <Grid item xs={4} key={index}>
                  <Box className="card-stat-summary--total">
                    <Typography variant="h6">{formatNumber(value)}</Typography>
                    <Typography variant="caption" className="card-stat-summary--total-subtitle">{label}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box className="card-bg">
            
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
