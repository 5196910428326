import React, { useState, useEffect } from "react";
import { req } from "../../utils";

import { Grid } from "@mui/material/";

import icon from "../../assets/images/dryer-alt-regular.svg";

export const Bc2Counts = ({ filterProps }) => {
  const { dateRanges, pageFilterByType, pageFilterByState, selectedDateRange } =
    filterProps;
  const [stats, setStats] = useState([]);

  const getScans = () => {
    if (!dateRanges) return;
    let url = "get_bc2_scans";
    //only add scan date range if both dates are selected
    //default selection is last 30 days
    let startDate = Math.floor(new Date().getTime() / 1000) - 30 * 24 * 60 * 60;
    let endDate = Math.floor(new Date().getTime() / 1000);
    if (dateRanges[0] && dateRanges[1]) {
      startDate = Math.floor(new Date(dateRanges[0]).getTime() / 1000);
      endDate = Math.floor(new Date(dateRanges[1]).getTime() / 1000);
      
    }
    url += `?s=${startDate}&e=${endDate}`;
    req("GET", url).then((data) => {
      let scanRatio = 0;
      try {
        scanRatio = (data.scans_with_non_null_upc / data.total_scans) * 100;
      } catch (e) {}
      if (isNaN(scanRatio)) {
        scanRatio = 0;
      }
      setStats([
        {
          label: "Scans with UPC",
          value: data.scans_with_non_null_upc?.toLocaleString(),
        },
        {
          label: "Scans without UPC",
          value: data.scans_with_null_upc?.toLocaleString(),
        },
        { label: "Total Scans", value: data.total_scans?.toLocaleString() },
        { label: "UPC Scan Ratio", value: `${scanRatio.toFixed(2)}%` },
      ]);
    });
  };

  useEffect(() => {
    getScans();
  }, [dateRanges]);

  const formatNumber = (number) => {
    try {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (e) {
      return number;
    }
  };

  const footerText = dateRanges
    ? `${new Date(dateRanges[0]).toLocaleString()} - ${new Date(
        dateRanges[1]
      ).toLocaleString()}`
    : "";

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={4}
        className={`${
          pageFilterByType !== "unfiltered" && pageFilterByType !== "machine"
            ? "d-none"
            : ""
        }`}
      >
        <div className="card card-stat-summary animate-bg-primary">
          <div className="card-body">
            <div className="card-stat-summary--title">BC2.0+ Scan Insights</div>
            <div className="card-stat-summary--total-wrapper">
              {stats.map(({ label, value }, index) => (
                <React.Fragment key={index}>
                  {index == 2 && <div style={{ width: "100%" }}></div>}
                  <div
                    className="card-stat-summary--total"
                    style={{ marginRight: "3rem" }}
                  >
                    {formatNumber(value)}
                    <div className="card-stat-summary--total-subtitle">
                      {label}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className="card-stat-summary--meta">
              <div className="card-stat-summary--meta-timeline">
                <span className="text-persiangreen">Date Range:</span>{" "}
                {footerText}
              </div>
            </div>
          </div>
          <div className="card-bg">
            <img src={icon} alt="Machine Icon" className="bg-icon" />
          </div>
        </div>
      </Grid>
    </>
  );
};
