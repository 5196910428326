import React, { useState, useEffect, useCallback, useMemo } from "react";
import { req } from "../../utils";
import { generateColorPalette } from "../../utils/generateColorPalette";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Bar } from 'react-chartjs-2';
import { Card, CardContent, CardHeader, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const ServiceCallTimes = ({ dateRanges }) => {
  const [jobStats, setJobStats] = useState([]);
  const [chartData, setChartData] = useState(null);

  const startTime = useMemo(() => moment(dateRanges[0]), [dateRanges]);
  const endTime = useMemo(() => moment(dateRanges[1]), [dateRanges]);

  const formatSecondsToTime = (seconds) => {
    let date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  };

  const fetchData = useCallback(async () => {
    try {
      const result = await req("GET", `service_calls_over_time?s=${startTime.unix()}&e=${endTime.unix()}`);
      
      if (result && result.length > 0) {
        const normalizedResult = result.map(item => ({
          ...item,
          service_call_type: normalizeServiceCallType(item.service_call_type)
        }));

        const serviceCallTypes = [...new Set(normalizedResult.map(item => item.service_call_type))];
        const colors = generateColorPalette(serviceCallTypes.length);
        
        const datasets = serviceCallTypes.map((type, index) => ({
          label: type,
          data: normalizedResult.map(item => 
            item.service_call_type === type ? item.total_duration_seconds : 0
          ),
          backgroundColor: colors[index],
        }));

        setChartData({
          labels: normalizedResult.map(item => item.call_date),
          datasets
        });
      }
    } catch (error) {
      console.error("Error fetching service calls over time:", error);
    }
  }, [startTime, endTime]);

  const fetchServiceCallInsights = useCallback(async () => {
    try {
      const url = `time_to_complete_calls?s=${startTime.unix()}&e=${endTime.unix()}`;
      const data = await req("GET", url);

      if (data && data.length > 0) {
        data.sort((a, b) => parseInt(a.average_completion_time_seconds) - parseInt(b.average_completion_time_seconds));
        const stats = data.map(item => ({
          label: item.normalized_service_call_type,
          value: formatSecondsToTime(item.average_completion_time_seconds),
        }));
        setJobStats(stats);
      }
    } catch (error) {
      console.error("Failed to fetch service call insights:", error);
    }
  }, [startTime, endTime]);

  useEffect(() => {
    fetchServiceCallInsights();
    fetchData();

    const intervalId = setInterval(fetchServiceCallInsights, 300000);
    return () => clearInterval(intervalId);
  }, [fetchServiceCallInsights, fetchData]);

  const normalizeServiceCallType = (type) => {
    const lowercaseType = type.toLowerCase();
    if (['preventative maintenance', 'preventitive maintenance', 'preventive maintenance'].includes(lowercaseType)) {
      return 'Preventative Maintenance';
    }
    return type;
  };

  const options = {
    scales: {
      x: {
        stacked: true,
        type: 'time',
        time: {
          unit: 'day'
        }
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          callback: (value) => formatSecondsToTime(value), // Format y-axis ticks to hh:mm:ss
        }
      }
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            // Here we're using the label callback instead of afterLabel to format the values properly
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += formatSecondsToTime(context.parsed.y); // Format the y value
            }
            return label;
          }
        }
      }
    }
  };

  if (!chartData || jobStats.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} lg={4}>
        <TableContainer component={Paper}>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>Service Call Type</TableCell>
                <TableCell align="right">AVG Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobStats.map(({ label, value }, index) => (
                <TableRow
                  key={`service-call-${index}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{label}</TableCell>
                  <TableCell align="right">{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Card>
          <CardHeader title="Service Call Times" />
          <CardContent>
            <Bar data={chartData} options={options} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}