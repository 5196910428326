import React, {  useState } from "react";
import { AppBar, Container, Divider, IconButton, ListItemIcon , Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import { List, Logout } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
import { logout, log } from "../utils";
import BC_logo_hor from "../assets/images/BC_logo_hor.png";
import BC_logo_hor_white from "../assets/images/BC_logo_hor_white.png";
export default function AppAppBar() {
    const userName = localStorage.getItem('_bc_tech_user');
    const handleSignOut = async () => {
        try {
            await logout();
        } catch (error) {
            log("Logout Error", error);
        }
    }


    const [anchorEl, setAnchorEl] = useState(null);
    
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
   
    return (
        <>
            <AppBar color="persiangreen" position="fixed" elevation={3} width="100%" className="bc-page--header">
                <Container disableGutters={true} maxWidth="xl" sx={{ flexGrow: 1 }}>
                    <Toolbar>
                        <Typography
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1 }}
                        >
                            <img src={BC_logo_hor_white} alt="BottleCycler Dashboards" className="logo" />
                        </Typography>
                
                        <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem style={{ flexDirection: "column" }}>
                                <div style={{ fontSize: ".75rem" }}>Signed in as:</div>
                                <div style={{ display: "block" }}>{userName}</div>
                            </MenuItem>
                            <Divider />
                            <MenuItem component={Link} to="/">
                                <ListItemIcon>
                                    <List fontSize="small" />
                                </ListItemIcon>
                                Dashboards
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={handleSignOut}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
}
