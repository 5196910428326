import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { log } from "./utils";
// CSS & Themes
import "./assets/css/default.css";
import { grey } from '@mui/material/colors';

// Layouts
import { AppLayout } from "./layouts/AppLayout";

// Pages
import Dashboards from "./pages/Dashboards";
import DataLayout from "./layouts/DataLayout";

import ProtectedRoute from "./components/ProtectedRoute";

// Theme
import { createTheme, ThemeProvider } from '@mui/material';

let theme = createTheme({
    // Theme customization goes here as usual, including tonalOffset and/or
    // contrastThreshold as the augmentColor() function relies on these
});

theme = createTheme(theme, {
    palette: {
        grey: theme.palette.augmentColor({
            color: {
              main: grey[500],
            },
            name: 'grey',
        }),
        lightergrey: theme.palette.augmentColor({
            color: {
              main: grey[200],
            },
            name: 'lightergrey',
        }),
        persiangreen: theme.palette.augmentColor({
            color: {
              main: '#00A49B',
            },
            name: 'persiangreen',
        }),
        tarawera: theme.palette.augmentColor({
            color: {
              main: '#063852',
            },
            name: 'tarawera',
        }),
    },
});

function App() {
    try {
        return (
            <ThemeProvider theme={theme}>
                <Router> {/* Add the Router component here */}
                    <Routes>
                        <Route element={<ProtectedRoute />}>
                            <Route element={<DataLayout />}>
                                <Route path="/" element={<AppLayout />}>
                                    <Route path="" element={<Dashboards />} />
                                </Route>
                            </Route>
                        </Route>
                
                        <Route path="*" element={<div>404</div>} />
                    </Routes>
                </Router>
            </ThemeProvider>
        );
    } catch (error) {
        log("error", "App.js", 0, "Error: " + error.message, error.stack);
    }
}

export default App;
