/*
Global utility functions, use as needed
 */
import { fetcher } from 'itty-fetcher';
export const base_url = 'https://app.bottlecycler.com/api/dashboards/';
const auth_storage_name = '_bc_dash_token';
let db;
export const logout = () => {
    localStorage.removeItem(auth_storage_name);
    window.location.reload();
}
export const time = () => {
    return Math.floor(Date.now() / 1000);
}
export const log = async (subject, data) => {
    let buildId = 0;
    if (process.env.REACT_APP_BUILD_ID)
        buildId = process.env.REACT_APP_BUILD_ID
    let logData = {
        subject,
        buildId,
        message: '',
        stack: '',
        browser: navigator.userAgent,
        location: window.location.href,
        timestamp: new Date().toISOString(),
        // user: USER_ID or USERNAME if available
    };

    if (data instanceof Error) {
        // Handle JavaScript Error objects
        logData.message = data.message;
        logData.stack = data.stack;
    } else if (typeof data === 'string') {
        // Handle string data
        logData.message = data;
    } else if (typeof data === 'object') {
        // Handle objects (non-error)
        logData = { ...logData, ...data }; // Merge additional data if it's an object
    }

    const logString = JSON.stringify(logData);

    if (process.env.NODE_ENV === 'development') {
        console.log("DEV so only console logging", subject, logString);
        // req('post', 'log_error', { subject, data: logString });
    } else {
        req('post', 'log_error', { subject, data: logString });
    }
}

export const req = async (method, url, data = {}) => {

    try {
        let options = {
            base: base_url,
            transformRequest(req) {
                req.headers['Authorization'] = localStorage.getItem(auth_storage_name); // TODO: improve to use storage with cookie backup  
                return req;
            },
        };
        if (url?.includes("http")) {
            delete options.base;
        }

        let f = new fetcher(options);
        method = method.toUpperCase();
        if (data) {
            const formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }
            try {

                return await f[method](url, formData);
            } catch (e) {
                //console.log("req error", e);
                // Store request for retry
                if (process.env.NODE_ENV !== 'development') {
                    storeFailedRequest(method, url, data);
                } else {
                    console.log("in prod would re-queue", e);
                }
            }
        } else {
            // Request without data
            return await f[method](url);
        }
    } catch (e) {
        if (process.env.NODE_ENV !== 'development') {
            storeFailedRequest(method, url, data);
        } else {
            console.log("in prod would re-queue", e);
        }
        return false;
    }
};
const storeFailedRequest = (method, url, data) => {
    const failedRequests = JSON.parse(localStorage.getItem('failedRequests') || '[]');
    const requestData = JSON.stringify(data); // Ensure consistent serialization for comparison

    // Check for duplicate requests without considering the timestamp
    const isDuplicate = failedRequests.some(request => {
        return request.method === method &&
            request.url === url &&
            JSON.stringify(request.data) === requestData;
    });

    if (!isDuplicate) {
        failedRequests.push({ method, url, data });
        localStorage.setItem('failedRequests', JSON.stringify(failedRequests));
    }
};


const retryFailedRequests = async () => {
    let failedRequests = JSON.parse(localStorage.getItem('failedRequests') || '[]');

    for (let i = 0; i < failedRequests.length; i++) {
        const { method, url, data } = failedRequests[i];
        try {
            const response = await req(method, url, data);
            if (response) {
                // On successful retry, remove the request from the queue
                failedRequests.splice(i, 1);
                i--; // Adjust index since we modified the array
                localStorage.setItem('failedRequests', JSON.stringify(failedRequests));
            }
        } catch (error) {
            console.error('Retry failed for request:', method, url, data, error);
            // If retry fails, keep it in the queue for next attempt
        }
    }
};

setInterval(retryFailedRequests, 30000); // Retry every 30 seconds

export const upload = async (url, file) => {
    if (!url.includes("http")) {
        url = `${base_url}${url}`; // Ensure base_url is correctly defined or imported
    }

    // Check for network availability before proceeding
    if (!navigator.onLine) {
        return Promise.reject({
            message: 'Network connection lost. Please check your internet connection and try again.',
            url: 'N/A',
            response: 'Network error: No connection'
        });
    }

    let xhr = new XMLHttpRequest();
    xhr.open('POST', url, true); // Open the request

    // Set the Authorization header right after opening the request
    const auth = localStorage.getItem(auth_storage_name); // Ensure this is the correct key for your auth token
    if (auth) {
        xhr.setRequestHeader('Authorization', auth);
    }

    xhr.withCredentials = false; // Set withCredentials after open, if needed

    return new Promise((resolve, reject) => {
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                try {
                    const response = JSON.parse(xhr.responseText);
                    resolve(response); // Resolve with the entire response
                } catch (e) {
                    reject({
                        message: `Error parsing JSON from ${url}: ${e.toString()}, response was: ${xhr.responseText}`,
                        url,
                        response: xhr.responseText
                    });
                }
            } else {
                reject({
                    message: `HTTP Error: ${xhr.status} from ${url}, response was: ${xhr.responseText}`,
                    url,
                    response: xhr.responseText
                });
            }
        };

        xhr.onerror = () => {
            reject({
                message: `Network error: ${xhr.statusText} from ${url}`,
                url,
                response: 'Network error'
            });
        };

        xhr.ontimeout = () => {
            reject({
                message: `Request timed out for ${url}`,
                url,
                response: 'Timeout error'
            });
        };

        xhr.send(file); // Sending the Blob or File object directly
    });
};



export const form_obj = (form) => {
    const form_data = new FormData(form);
    const form_data_obj = {};
    form_data.forEach((value, key) => (form_data_obj[key] = value));
    return form_data_obj;
}

export const update_parts = async () => {
    //looks at last update time and updates parts if needed
    //if updates are needed, then fetch update and clear local storage and update local storage
    let url = 'get_parts_last_update';
    let last_update = localStorage.getItem('parts_last_update');
    if (!last_update)
        last_update = 0;
    let remote_update_time = parseInt(await req('get', url));
    if (remote_update_time <= parseInt(last_update))
        return false;
    else {
        url = "get_parts";
        let parts = JSON.parse(await req('get', url));
        if (parts)
            localStorage.setItem('parts', JSON.stringify(parts));
        localStorage.setItem('parts_last_update', remote_update_time);
        return parts;
    }

}

export const addPartsToService = (part_id, quantity) => {
    //todo build
    console.log("UTILS part added to service", part_id, quantity);
}

export class Storage {
    // Constructor function

    // Method to set a value in local storage with an optional expiration
    set(name, value, expiration) {
        // Set the value in local storage
        //console.log("setting local storage", name, value);
        localStorage.setItem(name, value);

        // If expiration is specified, set the expiration date
        if (expiration) {
            const parts = expiration.match(/(\d+)([hdm])/);
            const amount = parseInt(parts[1], 10);
            const unit = parts[2];
            let milliseconds;
            if (unit === "h") {
                milliseconds = amount * 60 * 60 * 1000;
            } else if (unit === "d") {
                milliseconds = amount * 24 * 60 * 60 * 1000;
            } else if (unit === "m") {
                milliseconds = amount * 60 * 1000;
            }

            const date = new Date();
            date.setTime(date.getTime() + milliseconds);
            localStorage.setItem(name + "_expiration", date);
        }

        // Set the value in a cookie as a backup
        document.cookie = `${name}=${value}`;
    }

    // Method to get a value from local storage or a cookie if local storage is not available
    get = name => {
        // Get the value from local storage
        let storedValue = localStorage.getItem(name);

        // Check if the value has expired and remove it if it has
        const expirationDate = localStorage.getItem(name + "_expiration");
        if (expirationDate && new Date() > new Date(expirationDate)) {
            localStorage.removeItem(name);
            localStorage.removeItem(name + "_expiration");
            storedValue = null;
        }

        // If local storage is not available or the value is not set, try to get it from a cookie
        if (!storedValue) {
            const cookies = document.cookie.split(";");
            for (const cookie of cookies) {
                const parts = cookie.split("=");
                if (parts[0] === name) {
                    storedValue = parts[1];
                    break;
                }
            }
        }

        // Return the stored value
        return storedValue;
    }
    delete = name => {
        // Delete the value from local storage
        localStorage.removeItem(name);
        localStorage.removeItem(name + "_expiration");

        // Delete the value from a cookie
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    };
}
export const parseJsonProperties = (data) => {
    if (Array.isArray(data)) {
        return data.map(item => {
            return Object.entries(item).reduce((acc, [propKey, propValue]) => {
                acc[propKey] = typeof propValue === 'string' ? autoParseJSON(propValue) : propValue;
                return acc;
            }, {});
        });
    } else if (typeof data === 'object' && data !== null) {
        return Object.entries(data).reduce((acc, [propKey, propValue]) => {
            acc[propKey] = typeof propValue === 'string' ? autoParseJSON(propValue) : propValue;
            return acc;
        }, {});
    } else {
        return data;
    }
};
const autoParseJSON = (value) => {
    try {
        const parsed = JSON.parse(value);
        //console.log("parsed",parsed);
        if (Array.isArray(parsed)) {
            return parsed;
        } else if (typeof parsed === 'object' && parsed !== null) {
            return parsed;
        } else {
            return value; // Fallback to original value if not array or object
        }
    } catch {
        if (value.trim().startsWith("[")) {
            return []; // Fallback to empty array
        } else if (value.trim().startsWith("{")) {
            return {}; // Fallback to empty object
        } else {
            return value; // Fallback to original value
        }
    }
};
export function getFormattedWeekDates(inputDate) {
    let currentDate;

    // Check if inputDate is provided
    if (inputDate) {
        currentDate = new Date(inputDate);
    } else {
        // If no inputDate provided, use the current date
        currentDate = new Date();
    }

    // Find the Sunday of the current week in GMT
    const sunday = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate()));
    sunday.setUTCDate(currentDate.getUTCDate() - currentDate.getUTCDay());

    // Create an array to store the dates of the week
    const weekDates = [];

    // Loop through the days of the week and format each date
    for (let i = 0; i < 7; i++) {
        const day = new Date(sunday);
        day.setUTCDate(sunday.getUTCDate() + i);

        // Format the date as "YYYY-MM-DD"
        const formattedDate = day.toISOString().split('T')[0];
        weekDates.push(formattedDate);
    }

    return weekDates;
}

//added for muliple file uploads
export const resizeImage = (file, maxWidth, maxHeight) => new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
        let { width, height } = image;
        if (width > height) {
            if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
            }
        }
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);
        canvas.toBlob(blob => {
            const resizedFile = new File([blob], file.name, { type: file.type });
            resolve(resizedFile);
        }, file.type, 0.95);
    };
    image.onerror = reject;
});


  
export const uploadImage = async (path, file, metaData, progressCallBack) => {
    console.log("uploadImage", path, file, metaData, progressCallBack);
    const url = path.startsWith('http') ? path : `${base_url}${path}`;
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('meta_data', JSON.stringify(metaData));
    const auth_token = localStorage.getItem(auth_storage_name);
    const options = {
        method: 'POST',
        headers: { 'Authorization': auth_token },
        body: formData,
    };
    try{
        //add progress callback
        if (progressCallBack) {
            console.log("adding progress callback");
            options.onUploadProgress = (progressEvent) => {
                console.log("adding progressEvent ",progressEvent);
                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                progressCallBack(progress);
            };
        }
        let result = await fetch(url, options);
        if (result.status !== 200) {
            return false;
        }
        let data = await result.json();
        return data;

    }catch(e){
        console.log("uploadImage error",e);
        return false
    }
};



export const initDB = () => {
    return new Promise((resolve, reject) => {
        let db;
        try {
            if (!window.indexedDB) {
                throw new Error("IndexedDB is not supported in this browser.");
            }
            const request = window.indexedDB.open("fileUploadsDB", 1);
            
            request.onerror = (event) => {
                console.error("IndexedDB request error", event.target.error);
                reject(event.target.error);
            };

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                try {
                    const objectStore = db.createObjectStore("files", { keyPath: "id", autoIncrement: true });
                    objectStore.createIndex("pathFileName", ["path", "fileName"], { unique: true });
                } catch (e) {
                    console.error("IndexedDB upgrade error", e);
                    reject(e);
                }
            };

            request.onsuccess = (event) => {
                db = event.target.result;
                resolve(db);
            };
        } catch (e) {
            console.error("IndexedDB initialization error", e);
            reject(e);
        }
    });
};


export const getDB = async () => {
    if (!db) {
        db = await initDB();
    }
    return db;
};


export const getFailedUploads = async () => {
    const db = await getDB();
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(["files"], "readonly");
        const store = transaction.objectStore("files");
        const request = store.getAll();
        request.onsuccess = () => resolve(request.result);
        request.onerror = event => reject(event.target.error);
    });
};



export const storeFailedUpload = async (path, fileData, metaData, retryCount = 0) => {
  

    try {
        const fileAlreadyQueued = await isFileAlreadyQueued(path, fileData);
        if (fileAlreadyQueued) {
            console.log("Avoiding parallel universe duplication, file is already queued", path, fileData.name);
            return;
        }
        console.log("storeFailedUpload", path, fileData, metaData, retryCount);
        const db = await getDB();
        const transaction = db.transaction(["files"], "readwrite");
        const store = transaction.objectStore("files");

        // Check if an entry with the same path and fileName already exists
        const fileName = fileData.name;
        const request = store.index("pathFileName").get([path, fileName]);
        request.onsuccess = (event) => {
            const existingEntry = event.target.result;
            if (existingEntry) {
                // Entry already exists, update the retryCount
                const updatedEntry = { ...existingEntry, retryCount };
                store.put(updatedEntry);
            } else {
                // Entry doesn't exist, add a new entry
                const newData = { path, fileData, metaData, timestamp: new Date(), retryCount };
                store.put(newData);
            }
        };
    }catch(e){}
    
};
export const retryFailedUploads = async () => {
    if (!navigator.onLine) {
        console.log("offline who cares");
        return;
    }
    const onSuccessfulRetry = (file, path, metaData) => {
        console.log("onSuccessfulRetry", file, path, metaData);
    };
    const onFailedRetry = (file, path, metaData) => {
        console.log("onFailedRetry", file, path, metaData);
    };

    const db = await initDB();
    const transaction = db.transaction(["files"], "readwrite");
    const store = transaction.objectStore("files");
    const uploadsToRetry = [];
    const cursorRequest = store.openCursor();
    cursorRequest.onsuccess = event => {
        const cursor = event.target.result;
        if (cursor) {
            //get the db key
            let dbKey = cursor.key;
            const { fileData, path, metaData, retryCount } = cursor.value;
            uploadsToRetry.push({ fileData, path, metaData, retryCount,dbKey });
            cursor.continue();
        } else {
            processUploadRetries(uploadsToRetry, onSuccessfulRetry,onFailedRetry);
        }
    };
};

const processUploadRetries = async (uploads, onSuccess, onError) => {
    for (const upload of uploads) {
        const { fileData, path, metaData, retryCount, dbKey } = upload;

        // Generate a unique identifier to check if this item is already being processed.
        const uploadIdentifier = `${path}_${fileData.name}`;


        try {
            const success = await uploadImage(path, fileData, metaData, onSuccess, onError);
            if (success) {
                await removeFailedUpload(dbKey);
            }
        } catch (error) {
            console.log("processUploadRetries error",error);
        } 
    }
};

const removeFailedUpload = async (dbKey) => {
    const db = await getDB(); // Make sure getDB is defined and properly imported
    const transaction = db.transaction(["files"], "readwrite");
    const store = transaction.objectStore("files");

    // Asynchronous deletion must be wrapped in a transaction completion promise
    return new Promise((resolve, reject) => {
        const request = store.delete(dbKey);
        request.onsuccess = () => {
            console.log(`Removed upload with dbKey: ${dbKey} from the queue.`);
            resolve();
        };
        request.onerror = () => {
            console.error(`Error removing upload with dbKey: ${dbKey}`, request.error);
            reject(request.error);
        };
    });
};

const isFileAlreadyQueued = async (path, fileData) => {
    const db = await getDB();
    const transaction = db.transaction(["files"], "readonly");
    const store = transaction.objectStore("files");
    const index = store.index("pathFileName");
    const request = index.get([path, fileData.name]);

    return new Promise((resolve) => {
        request.onsuccess = () => {
            // If the request finds an entry, it means the file is already queued
            if (request.result) {
                resolve(true); // File is already queued
            } else {
                resolve(false); // File is not queued
            }
        };
        request.onerror = () => {
            resolve(false); // Assume not queued on error, or handle differently as needed
        };
    });
};
setInterval(retryFailedUploads, 30000); // Retry every 30 seconds


export function getColor(index) {
    let colors = ['#00A49B','#a6cee3','#1f78b4','#b2df8a','#33a02c','#fb9a99','#e31a1c','#fdbf6f','#ff7f00','#cab2d6','#6a3d9a','#ffff99','#b15928'];
    const colorIndex = index % colors.length;
    return colors[colorIndex];
}