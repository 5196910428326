import { useState, useEffect } from 'react';
import { req } from "../utils";

export const useEnvironmentalStats = (start, end, state) => {
  const [data, setData] = useState(null);
  const [updateDate, setUpdateDate] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      
          
      let url = `environmental_stats?s=${start}&e=${end}`;
      if (state && state !== 'unfiltered') {
       url+=`&state=${state}`;
      }
      try {
        const result = await req("GET", url);
        setData(result);
        setUpdateDate(new Date());
      } catch (error) {
        console.error('Failed to fetch environmental stats:', error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 300000); // Refresh every 5 minutes
    return () => clearInterval(intervalId);
  }, [start, end, state]);

  return { data, updateDate };
};