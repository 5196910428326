import React, { useState, useEffect } from "react";

import { req } from "../../utils";

import { Grid } from "@mui/material/";

import leafIcon from '../../assets/images/leaf-regular.svg';

export const BinsCollected = ({ filterProps }) => {
  const {
    dateRanges,
    pageFilterByType,
    pageFilterByState,
    selectedDateRange,
  } = filterProps;
  //console.log('BinsCollected filterProps:', filterProps);
  const [binData, setBinData] = useState({
    totalBinsCollected: '',
    lastUpdated: ''
  });

  useEffect(() => {
    if (!dateRanges[0] || !dateRanges[1]) return;

    const fetchBinCount = async () => {
      const startDate = Math.floor(new Date(dateRanges[0]).getTime() / 1000);
      const endDate = Math.floor(new Date(dateRanges[1]).getTime() / 1000);
      let url = `get_bins_collected?s=${startDate}&e=${endDate}`;
    
      if(pageFilterByState){
        url += `&state=${pageFilterByState}`;
      }
      try {
        const data = await req("GET", url);
        setBinData({
          totalBinsCollected: data.nbr_bins_collected.toLocaleString(),
          lastUpdated: new Date().toLocaleTimeString()
        });
      } catch (error) {
        console.error('Failed to fetch bin count:', error);
      }
    };

    fetchBinCount();
    // Refresh every 5 minutes
    const intervalId = setInterval(fetchBinCount, 300000);
    return () => clearInterval(intervalId);
  }, [dateRanges, pageFilterByState]);

  const stats = [
    { label: 'Total Bins Collected', value: binData.totalBinsCollected }
    // Add more stats as needed
  ];

  const footerText = dateRanges
  ? `${new Date(dateRanges[0]).toLocaleString()} - ${new Date(dateRanges[1]).toLocaleString()}`
  : "";

  const formatNumber = (number) => {
    try {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (e) {
      return number;
    }
  };

  return (<>
    <Grid item xs={12} sm={12} md={6} lg={4} className={`${pageFilterByType !== 'unfiltered' && pageFilterByType !== 'environmental' ? 'd-none' : ''}`}>
      <div className="card card-stat-summary animate-bg-primary">
          <div className="card-body">
          <div className="card-stat-summary--title">
            {stats.length > 0 && stats[0]?.label}
          </div>
          <div className="card-stat-summary--total">
            {stats.length > 0 && formatNumber(stats[0]?.value)}
          </div>
          <div className="card-stat-summary--meta">
            <div className="card-stat-summary--meta-timeline"><span className="text-persiangreen">Date Range:</span> {footerText}</div>
          </div>
          </div>
          <div className="card-bg">
            <img src={leafIcon} alt="Leaf Icon" className="bg-icon" />
          </div>
      </div>
    </Grid>
    {/* <DashboardStat
      title="Bins Collected"
      stats={stats}
      classes='time-ranged'
      footerText={`Data range: ${dateRanges[0]?.toLocaleString()} - ${dateRanges[1]?.toLocaleString()}`}
    /> */}
  </>);
};
