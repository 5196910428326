import React, { useMemo } from "react";
import { Grid } from "@mui/material/";
import leafIcon from '../../assets/images/leaf-regular.svg';
import { useEnvironmentalStats } from "../../hooks/useEnvironmentalStats";
import { getDateRange } from "../../utils/dateUtils";

export const EnvironmentalImpact = ({ filterProps }) => {
  const { dateRanges, pageFilterByType, pageFilterByState } = filterProps;
  const { start, end } = getDateRange(dateRanges);

  const { data, updateDate } = useEnvironmentalStats(start, end, pageFilterByState);

  const envData = useMemo(() => [
    { label: 'Total Glass (tons)', value: data?.total_tons },
    { label: 'Energy Savings (GJ)', value: data?.energy_savings_gj },
    { label: 'Water Savings (Litres)', value: data?.water_savings_litres },
    { label: 'Landfill Space Saved (m³)', value: data?.landfill_space_saved_m3 },
    { label: 'CO2 Savings (Tonnes)', value: data?.co2_savings_tonnes }
  ], [data]);

  const formatNumber = (number) => {
    return number?.toLocaleString() ?? '0';
  };

  const isVisible = pageFilterByType === 'unfiltered' || pageFilterByType === 'environmental';

  if (!isVisible) return null;

  return (
    <>
      {envData.map(({ label, value }, index) => (
        <Grid item key={index} xs={12} sm={12} md={6} lg={4}>
          <div className='card card-stat-summary animate-bg-primary'>
            <div className="card-body">
              <div className="card-stat-summary--title">{label}</div>
              <div className="card-stat-summary--total">
                {formatNumber(value)}
              </div>
              <div className="card-stat-summary--meta">
                <div className="card-stat-summary--meta-timeline">
                  {`Updated: ${updateDate.toLocaleTimeString()}`}
                </div>
              </div>
            </div>
            <div className="card-bg">
              <img src={leafIcon} alt="Leaf Icon" className="bg-icon" />
            </div>
          </div>
        </Grid>
      ))}
    </>
  );
};
