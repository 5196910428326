import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, InfoWindow, useLoadScript } from '@react-google-maps/api';
import { Card, CardContent, CardHeader } from '@mui/material';

import { req } from '../../utils';
const mapContainerStyle = {
  height: "500px",
  width: "100%"
};

const center = {
  lat: -30,
  lng: 144
};

export const VenuesWithMachines = () => {
  const [venueData, setVenueData] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });
  const fetchData = async () => {
    const result = await req("GET", "venues_with_machines");
    console.log("venues with machines", result);
    setVenueData(result);
  };
  useEffect(() => {
   

    fetchData();
  }, []);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Card>
      <CardContent sx={{ padding: '0 !important' }}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={5}
          center={center}
          options={{ scrollwheel: true }} 
        >
          {venueData.map((venue, index) => (
            <Marker
              key={index}
              position={{ lat: parseFloat(venue.latitude), lng: parseFloat(venue.longitude) }}
              onClick={() => setSelectedVenue(venue)}
              icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: getColorBasedOnMachines(venue.number_of_machines),
                fillOpacity: 0.7,
                scale: 10,
                strokeColor: 'white',
                strokeWeight: 1
              }}
            />
          ))}

          {selectedVenue && (
            <InfoWindow
              position={{ lat: parseFloat(selectedVenue.latitude), lng: parseFloat(selectedVenue.longitude) }}
              onCloseClick={() => setSelectedVenue(null)}
            >
              <div>
                <h2>{selectedVenue.venue_name}</h2>
                <p>Number of machines: {selectedVenue.number_of_machines}</p>
                
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </CardContent>
    </Card>
  );
};

function getColorBasedOnMachines(numberOfMachines) {
    if (numberOfMachines >= 7) return '#FF5555';
    if (numberOfMachines === 6) return '#CC6655';
    if (numberOfMachines === 5) return '#997766';
    if (numberOfMachines === 4) return '#668877';
    if (numberOfMachines === 3) return '#339988';
    return '#00A49B'; // Default color for 2 or fewer machines
  }
  