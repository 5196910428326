
import { useState } from "react";

import { FormControl, MenuItem, Select } from "@mui/material/";


export default function YearRange({onChangeYear}) {

	/** Year Ranges */
	const firstYear = 2023;
	const currentYear = new Date().getFullYear();
	const years = Array.from({ length: currentYear - firstYear + 1 }, (_, index) => currentYear - index);

	const [selectedYear, setSelectedYear] = useState(currentYear);

	const handleDateChange = (event) => {
        setSelectedYear(event.target.value);
		onChangeYear(event.target.value);
    };


	return (
		<div>
			<FormControl fullWidth color='grey' size='small'>
				<Select
					labelId="labelFilterDate"
					id="selectFilterDate"
					onChange={handleDateChange}
					value={selectedYear}
				>
					{years.map((option) => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
}
